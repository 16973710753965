<template>
  <v-img
    src="@/assets/bg_landing.png"
    lazy-src="@/assets/bg_landing_small.jpg"
    height="100%"
    width="100%"
    class="bg-cover"
  >
    <v-container
      fluid
      class="d-flex flex-column justify-center align-center"
      style="height: 100%"
    >
      <v-card class="mr-3 mt-10 justify-center" max-width="500px" color="white">
        <v-card-title class="mx-3">
          <v-img src="@/assets/title_logo.svg" max-width="270px" />
          <v-text-field
            v-model="email"
            label="Email Address"
            dense
            rounded
            filled
            background-color="white"
            class="mt-3"
            @change="onChange"
            outlined
            type="email"
          />
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            @change="onChange"
            label="Password"
            dense
            rounded
            filled
            outlined
            background-color="white"
            class="pb-0 mb-0"
            full-width
            style="width: 100%"
            @keypress.enter="loginClicked"
          />
          <v-alert type="error" v-if="errorMessage">
            {{ errorMessage }}
          </v-alert>
          <div
            class="d-flex"
            style="justify-content: space-between; align-items: flex-end; width: 100%;"
          >
            <v-checkbox v-model="rememberme" label="Remember Me" class="ml-3" />

            <v-btn
              text
              class="text-none mb-4"
              rounded
              @click="goToForgotPassword"
            >
              Forgot Password?
            </v-btn>
          </div>

          <v-btn
            rounded
            dark
            color="#7024c4"
            width="100%"
            @click="loginClicked"
            :loading="loading"
            >Log In</v-btn
          >
        </v-card-title>
        <v-card-title class="justify-center">
          <v-btn text class="text-none mb-4" rounded @click="goToRegister"
            >Register</v-btn
          >
        </v-card-title>
      </v-card>
    </v-container>
  </v-img>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      errorMessage: null,
      rememberme: true,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      showPassword: false,
    };
  },
  methods: {
    ...mapActions({
      onLogin: "auth/onLogin",
    }),
    loginClicked() {
      if (this.loading) return;
      const params = {
        email: this.email,
        password: this.password,
        rememberme: this.rememberme,
      };
      this.loading = true;
      this.onLogin(params)
        .then(() => {
          this.loading = false;
          this.emailError = null;
          this.passwordError = null;
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = error.message;
          }
        });
    },
    onChange() {
      this.errorMessage = null;
    },
    goToForgotPassword() {
      this.$router.push({ name: "forgotpassword" });
    },
    goToRegister() {
      this.$router.push({ name: "register" });
    },
  },
};
</script>
<style>
.welcome-title {
  font-family: "Poppins-Bold";
  font-size: 50px;
  text-shadow: 2px 2px 12px #0006;
  color: white;
  padding-left: 20px;
  padding-top: 50px;
  padding-bottom: 10px;
  display: block;
}
.welcome-description {
  font-family: "Poppins-Medium";
  font-size: 20px;
  padding-left: 20px;
  display: block;
}
.bg-blur {
  background: #fffc;
  position: absolute;
  height: 100%;
  width: 100%;
}
.bg-cover > .v-image__image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
</style>
